import {gql, useQuery} from "@apollo/client";
import {useParams} from "react-router-dom";
import {useTracking} from "../tracking";
import {useEffect} from "react";

const HELLO_WORLD = gql`

    query HelloOlivia($name:String){
        helloWorld(name: $name)
}

`

export const HelloWorld = () => {


    const {pageView} = useTracking()

    useEffect(() => {

        pageView("hello world")
    }, [window.location.href])

    const {name} = useParams()
    const {data, loading} = useQuery(HELLO_WORLD, {
        variables: {name}
    })
    console.log(name)

    if (loading) {
        return 'loading'
    }
    return data.helloWorld
}

