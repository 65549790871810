import {ADD_TO_CART, REMOVE_FROM_CART, EMPTY_CART} from "../reducers/cartReducer";


export const addToCart = (id, quantity) => {
    return {
        type: ADD_TO_CART,
        item: id,
        quantity: quantity
    }
}

export const removeFromCart = (id, quantity) => {
    return {
        type: REMOVE_FROM_CART,
        item: id,
        quantity: quantity
    }

}

export const emptyCart = () => {
    return {
        type: EMPTY_CART
    }
}