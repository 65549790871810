import style from './ProductTile.module.css'
import {addToCart} from '../../redux/actions/cartActions'
import {Link, useNavigate} from "react-router-dom"
import {useDispatch} from "react-redux"
import {useTracking, useVisible} from "../../tracking"
import {useEffect, useRef} from "react"


export const ProductTile = ({product, location, position=0}) => {
    const dispatch = useDispatch()

    const {productImpression, productClick, addToCart:trackingAddToCart, setTriggered, shouldTrigger} = useTracking()
    const ref = useRef()
    const navigate = useNavigate()
    const isVisible = useVisible(ref)

    useEffect(
        () => {
            if (isVisible && shouldTrigger) {
                setTriggered()
                productImpression(product, position)
            }
        }, [isVisible, shouldTrigger]
    )

    return (
        <div className={style.tile}>
            <img className={style.imageGrid} src={product?.image}/>
            <h4 className={style.titleLink} ref={ref} key={product?.id}
                 onClick={()=>{
                        productClick(product, location, position)
                        navigate(`/product/${product.id}`)
                  }}
            >
                    {product?.productName}
            </h4>
            <h3 className={style.addToCartButton}>
                <button className={style.addToCartButtonContent}
                    onClick={() =>{
                        dispatch(addToCart(product.id, 1))
                        trackingAddToCart(product, location)
                        }}
                    >
                    Add to Cart
                </button>
            </h3>
            {/*<h3> {p?.productCategory}</h3>*/}
        </div>

    )
}