import style from "./OrderSuccess.module.css"
import {Link, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import banner from "../../res/banner_image/scott-webb-C6oPXOatFD8-unsplash.jpg"
import {useEffect, useState, useRef} from "react"
import {useTracking, useVisible} from "../../tracking"



export const OrderSuccess = () => {

   return (<>
            <div className={style.banner}>
                <img className={style.image} src={banner}/>
                <div className={style.bannerContent}>
                    <h3 className={style.title}> Analytics Sandbox </h3>
                </div>
            </div>
            <div className={style.container}>
                  <div>
                   <h3 className={style.header}> Bestellbestätigung </h3>
                   <h3 className={style.subHeader}> Bestellnummer: 0000 0000 0000 0000 </h3>
                  </div>
            </div>
            </>
   )
}