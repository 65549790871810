import {cloneDeep} from "lodash"

const CART = "cart"

const InitialState = JSON.parse(localStorage.getItem(CART)) || {}

export const ADD_TO_CART = "ADD TO CART"
export const REMOVE_FROM_CART = "REMOVE FROM CART"
export const EMPTY_CART = "EMPTY CART"


export const cartReducer = (state=InitialState, {type, item, quantity}) => {
    const newState = cloneDeep(state)
    switch (type){
        case ADD_TO_CART:
            if (newState[item]) {
                newState[item] += quantity
            } else {
                newState[item] = quantity
            }
            localStorage.setItem(CART, JSON.stringify(state))
            return newState
        case REMOVE_FROM_CART:
            if (newState[item]) {
               newState[item] -= quantity
            }
            if (isNaN(newState[item]) || (newState[item]<1 )) {
                delete newState[item]
            }
            localStorage.setItem(CART, JSON.stringify(state))
            return newState
        case EMPTY_CART:
            localStorage.setItem(CART, JSON.stringify({}))
            return {}
        default:
            return state
    }
}






// negative items