import {NavigationBanner} from "../banner/NavigationBanner";
import {NavigationBar} from "../navigationbar/NavigationBar";



export const Layout = ({children}) => {

    return (
           <div>
                 <NavigationBanner/>
                    {children}
{/*                  <Footer> */}
           </div>
    )
}