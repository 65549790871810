import style from "./OrderSummary.module.css"
import {Link, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import banner from "../../res/banner_image/scott-webb-C6oPXOatFD8-unsplash.jpg"
import {addToCart, emptyCart, removeFromCart} from '../../redux/actions/cartActions'
import {useEffect, useState, useRef} from "react"
import {useTracking, useVisible, WK} from "../../tracking"



export const OrderSummary = () => {

   return (<>
            <div className={style.banner}>
                <img className={style.image} src={banner}/>
                <div className={style.bannerContent}>
                    <h3 className={style.title}> Analytics Sandbox </h3>
                </div>
            </div>
         <div className={style.container}>
              <div className={style.productContainerLeft}>
                  <div>
                   <h3 className={style.header}> Bestellübersicht </h3>
                  </div>
                  <div>
                      <h3 className={style.subHeader}> Payment Method </h3>
                          <select className={style.dropdown}>
                              <option value="Credit Card">Credit Card</option>
                              <option value="Paypal">Paypal</option>
                          </select>
                  </div>
                  <div>
                      <h3 className={style.subHeader}> Shipping </h3>
                          <select className={style.dropdown}>
                              <option value="Standard">Standard</option>
                              <option value="Express">Express</option>
                          </select>
                  </div>
              </div>
             <div className={style.productContainerRight}>
                 <h3 className={style.cartSum}>
                     <span> TOTAL: </span>
 {/*                     <span> TOTAL: {productSum} €  </span> */}
                </h3>
                <h3 className={style.orderButton}>
                    <button className={style.orderButtonContent}>
                            Zahlung Bestätigen
                    </button>
                </h3>
            </div>
        </div> </>
   )
}




