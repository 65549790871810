import {gql, useQuery} from "@apollo/client"
import style from "./ProductDisplay.module.css"
import {ProductTile} from "../product_tile/ProductTile"
import {Layout} from "../layout/Layout"
import {useParams} from "react-router-dom"
import {useTracking, CATEGORY} from "../../tracking"
import {useEffect} from "react"


const PRODUCTS = gql`

query ($id: UUID!) {
  category(categoryId: $id) {
    categoryName
    id
    products {
      productName
      id
      image
    }
  }
}
`


export const ProductDisplay = () => {
    const {category_id} = useParams()
    const {data, loading} = useQuery(PRODUCTS, {
        variables: {
            "id": category_id
        }
    })

    const {pageView} = useTracking()

    useEffect(() => {

        pageView("category")
    }, [window.location.href])

    if (loading) {
        return 'loading'
    } else {
        return (
            <Layout>
                <h3 className={style.headerText}> {data?.category?.categoryName} </h3>
                {/*<h3>
                      {
                          p?.productCategory
                      }
                  </h3>*/}
                <div className={style.grid}>
                    {
                        data?.category?.products?.map((p, i) => (
                            <ProductTile key={p.id} product={p} location={CATEGORY} position={i} />
                        ))
                        }
                </div>
            </Layout>
        )
    }
}



