import {SET_VERSION, RESET} from "../reducers/trackingReducer"

export const setTrackingVersion = (version) => {
    return {
        type: SET_VERSION,
        version
    }
}

export const resetTracking = () => {

    window.dataLayer = []

    return {
        type: RESET
    }
}