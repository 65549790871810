import {gql, useQuery} from "@apollo/client";
import style from "./ProductDetail.module.css";
import {Layout} from "../layout/Layout";
import {useParams} from "react-router-dom";
import {useTracking, PDP} from "../../tracking";
import {addToCart} from '../../redux/actions/cartActions';
import {useDispatch} from "react-redux";
import {useEffect} from "react";


const PRODUCT = gql`

query ($id: UUID!) {
    product(productId: $id) {
      id
      productName
      productDescription
      price
      image
    }
   }
`

export const ProductDetail = () => {
    const {product_id} = useParams()
    const dispatch = useDispatch()
    const {data, loading} = useQuery(PRODUCT, {
        variables: {
            "id": product_id
        }
    })

    const {pageView, addToCart:trackingAddToCart} = useTracking()

    useEffect(() => {

        pageView("detail")
    }, [window.location.href])

    const {addProductFromDetail} = useTracking()

    if (loading) {
        return 'loading'
    } else {
        return (
            <Layout>
                <div className={style.flexContainer}>
                   <div className={style.imageDisplay}>
                         <img className={style.imageSize} src={data?.product?.image}/>
                   </div>
                   <div className={style.textDisplay}>
                         <h3 className={style.detailHeaderText}> {data?.product?.productName} </h3>
                         <h3 className={style.detailText}>{data?.product?.price} € </h3>
                         <h3 className={style.detailTextDescription}>{data?.product?.productDescription}</h3>
                         <h3 className={style.addToCartButton}>
                            <button className={style.addToCartButtonContent}
                                onClick={()=>{
                                    dispatch(addToCart(data?.product?.id, 1))
                                    trackingAddToCart(data?.product, PDP)
                                    }}
                                >
                                Add to Cart
                            </button>
                         </h3>
                   </div>
                 </div>
             </Layout>
        )
    }
}





