import React from 'react'
import style from "./NavigationBanner.module.css";
// import banner from "../../res/banner_image/jason-wang-NxAwryAbtIw-unsplash.jpg";
import banner from "../../res/banner_image/scott-webb-C6oPXOatFD8-unsplash.jpg";
import {NavigationBar} from "../navigationbar/NavigationBar";
import {Link} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux"
import {layers} from "../../tracking";
import {resetTracking, setTrackingVersion} from "../../redux/actions/trackingActions";


export const NavigationBanner = () => {
    const cart = useSelector(s => s.cart)
    const version = useSelector(s => s.trackingVersion)
    const quantity = Object.values(cart).reduce((oldValue, newValue) => oldValue + newValue, 0)

    const dispatch = useDispatch()

    return (
        <div className={style.banner}>
{/*         style={{backgroundImage: banner}} */}
            <img className={style.image} src={banner}/>
            <div className={style.bannerContent}>
                <div className={style.dataLayer}>
                    <select className={style.dataLayerDropdown}
                        value={version}
                        onChange={evt => dispatch(setTrackingVersion(evt.target.value))}
                    >
                        {layers.map(l => <option key={l} value={l}>{l}</option>)}
                    </select>
                    <button className={style.dataLayerButton}
                        onClick={() => dispatch(resetTracking())}
                    >
                        Reset Datalayer
                    </button>
                </div>
            <h3 className={style.cartLink}><Link to="/cart">Warenkorb {quantity}</Link></h3>
            <h3 className={style.title}> Analytics Sandbox </h3>

            <h3 className={style.navBar}>
                <NavigationBar className={style.navBarBanner}></NavigationBar>
            </h3>
            </div>
        </div>
    )
}



