import {Route, Routes} from "react-router-dom";
import {HelloWorld} from "./components/HelloWorld";
import {ProductDisplay} from "./components/product_display/ProductDisplay";
import {ProductDetail} from "./components/product_detail/ProductDetail";
import {Home} from "./components/home/Home";
import {Cart} from "./components/cart/Cart";
import {OrderSummary} from "./components/order_summary/OrderSummary";
import {OrderSuccess} from "./components/order_success/OrderSuccess";


export const App = () => {
   return (
       <Routes>
          <Route path="hello/:name" element={<HelloWorld/>}/>
           <Route path="hello/" element={<HelloWorld/>}/>
           <Route path="/" element={<Home/>}/>
           <Route path="/products/:category_id" element={<ProductDisplay/>}/>
           <Route path="/product/:product_id" element={<ProductDetail/>}/>
           <Route path="/cart" element={<Cart/>}/>
           <Route path="/ordersummary" element={<OrderSummary/>}/>
           <Route path="/ordersuccess" element={<OrderSuccess/>}/>

       </Routes>
   )
}



