import {gql, useQuery} from "@apollo/client";
import style from "./Home.module.css";
import {ProductTile} from "../product_tile/ProductTile";
import {Layout} from "../layout/Layout";
import {useTracking, HOME} from "../../tracking";
import {useEffect} from "react";


const PRODUCTS = gql`

query {
    products(limit:3) {
      productName
      id
      image
    }
}
`

// const ProductsDisplayVersion = ({product, match}) => <Link to={`${match.url}`}/${products.productCategory}>

/* */
export const Home = () => {
    const {data, loading} = useQuery(PRODUCTS)

    const {pageView} = useTracking()

    useEffect(() => {

        pageView("home")
    }, [window.location.href])

    if (loading) {
        return 'loading'
    } else {
        return (
            <Layout>
                <h3 className={style.introText}>Lorem ipsum dolor sit amet, consectetuer adipiscing elit. Aenean commodo
                    ligula eget dolor.
                    Aenean massa. Cum sociis natoque penatibus et magnis dis parturient montes, nascetur ridiculus mus.
                    Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem. Nulla consequat massa quis
                    enim.
                    Donec pede justo, fringilla vel, aliquet nec, vulputate eget, arcu. In enim justo, rhoncus ut, imperdiet a, venenatis vitae, justo.
                    Nullam dictum felis eu pede mollis pretium. Integer tincidunt. Cras dapibus.
                    Vivamus elementum semper nisi. Aenean vulputate eleifend tellus. Aenean leo ligula, porttitor eu, consequat vitae, eleifend ac, enim.
                </h3>
                <hr/>
                <h3 className={style.headerText} > Top-Produkte </h3>
                <div className={style.picture}>
                    {
                        data?.products.map(p => (
                            <ProductTile product={p} location={HOME}/>

                        ))
                    }
                </div>
            </Layout>
        )
    }
}



