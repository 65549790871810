import {gql, useQuery} from "@apollo/client"
import style from "./Cart.module.css"
import {Layout} from "../layout/Layout"
import {Link, useNavigate} from "react-router-dom"
import {useDispatch, useSelector} from "react-redux"
import {addToCart, emptyCart, removeFromCart} from '../../redux/actions/cartActions'
import {useEffect, useState, useRef} from "react"
import {useTracking, useVisible, WK} from "../../tracking"

const CART_PRODUCTS = gql`

query ($ids: [UUID!]) {
    products(productIds: $ids) {
    	id
      productName
      productDescription
      price
      vatAmount
      image
  }
}
`

export const Cart = () => {
    const ref = useRef()
    const navigate = useNavigate()
    const isVisible = useVisible(ref)
    const dispatch = useDispatch()
    const cart = useSelector(s => s.cart)
    const productIds = Object.keys(cart)
    const quantity = Object.values(cart).reduce((oldValue, newValue) => oldValue + newValue, 0)
    const {data, loading} = useQuery(CART_PRODUCTS,
        {
            variables: {
                ids: productIds
            }
        }
    )

    const {pageView, cartView, productImpression, productClick, addToCart:trackingAddToCart, removeFromCart:trackingRemoveFromCart, orderConfirmation,
               shouldTrigger, setTriggered} = useTracking()

    const [productSum, setProductSum] = useState("...")
    useEffect(() => {
        if (Array.isArray(data?.products)) {
            const itemTotal = data?.products?.map((p, i) => (p.price * cart[p.id]))
            const countSum = itemTotal.reduce((oldValue, newValue) => oldValue + newValue, 0)
            setProductSum(countSum.toFixed(2))
//              adjust rounding  Math.round((num + Number.EPSILON) * 100) / 100

            if (shouldTrigger) {
                pageView("cart")
                cartView(data?.products)
                setTriggered()
            }
        }
    }, [cart, cartView, data?.products, pageView, trackingAddToCart, trackingRemoveFromCart, orderConfirmation,
        setTriggered, shouldTrigger])


    if (loading){
        return 'loading'
    }

        return (
            <Layout>
                <div className={style.outerProductContainer}>
                    <div className={style.productContainerLeft}>
                    {data?.products?.map((p, i) => (
                        <div>
                         <h3 className={style.imageDisplay}>
                                 <img className={style.imageSize} src={p.image}/>
                         </h3>
{/*                          <h3 className={style.detailHeaderText}><Link to={`/product/${p.id}`}>{p?.productName}</Link></h3> */}
                         <h3 className={style.detailHeaderText} ref={ref} key={p?.id}
                              onClick={()=>{
                                     productClick(p, WK, i)
                                     navigate(`/product/${p.id}`)
                               }}
                         >
                                 {p?.productName}
                         </h3>
                         <div className={style.productAmount}>
                            <div className={style.productAmountDetail}>
                                <h3 className={style.detailText}>
                                    <span>{p.price} € </span>
                                </h3>
                            </div>
                            <div className={style.productAmountDetail}>
                                <button className={style.productAmountButtonLeft}
                                    onClick={()=>{
                                        dispatch(removeFromCart(p.id, 1))
                                        trackingRemoveFromCart(p, WK)
                                    }}
                                    >
                                        -
                               </button>
                            </div>
                            <div className={style.productAmountDetail}>
                                  <h3 className={style.circle}> {cart[p.id]}
{/*                                         <span className={style.circleNumber}> {cart[p.id]}</span> */}
                                  </h3>
                            </div>
                            <div className={style.productAmountDetail}>
                                <button className={style.productAmountButtonRight}
                                    onClick={()=>{
                                        dispatch(addToCart(p.id, 1))
                                        trackingAddToCart(p, WK)
                                        }}
                                    >
                                        +
                               </button>
                            </div>
                        </div>
{/*                         <div> <button onClick={()=>dispatch(removeFromCart(p.id, cart[p.id]))}>Delete</button></div>  */}
{/*                         <h3 className={style.cartDetails}> Gesamtsumme {p.price * cart[p.id]} </h3> */}
                        </div>
                    ))}
                    </div>
                    <div className={style.productContainerRight}>
                        <h3 className={style.quantity}> {quantity} Artikel </h3>
                        <hr></hr>
                        <h3 className={style.cartSum}>
                            <span> TOTAL: {productSum} €  </span>
                        </h3>
                        <h3 className={style.orderButton}>
                            <button className={style.orderButtonContent}
                                onClick={()=>{
                                    dispatch(emptyCart())
                                    orderConfirmation(data?.products, WK)
                                    }}
                                >
                                    Zahlung Bestätigen
                            </button>
                        </h3>
                    </div>
                    <div>
                       <h3 className={style.orderButton}>
                            <button className={style.emtpyCart}
                                onClick={()=>{
                                    dispatch(emptyCart())
                                    }}
                               >
                                    Warenkorb Leeren
                            </button>

                        </h3>
                    </div>
                </div>
            </Layout>
        )
    }


// add quantity text field for manual input