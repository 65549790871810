import React from 'react';
import {BrowserRouter, Link} from "react-router-dom";
import style from "./NavigationBar.module.css";
import {gql, useQuery} from "@apollo/client";


const CATEGORIES  = gql`

query {
  categories{
    categoryName
    id
  }
}
`

export const NavigationBar = () => {
    const {data}= useQuery(CATEGORIES)
 return (

             <div className={style.navBarContainer}>
                          <h3 className={style.navBarStyle}><Link to="/">Home</Link></h3>
                           {data?.categories?.map(c => (
                           <h3 className={style.navBarStyle} key={c.id} ><Link to={`/products/${c.id}`}>{c.categoryName}</Link></h3>
                      ))}
             </div>

    )
}
