import {NONE} from "../../tracking"
// import Cookies from "universal-cookie"

const DATALAYER = "dataLayer"

// const cookies = new Cookies()

// const initialState = cookies.get("dataLayerVersion") || NONE
const initialState = localStorage.getItem(DATALAYER) || NONE


export const SET_VERSION = "SET_VERSION"
export const RESET = "RESET"

export const trackingReducer = (state = initialState, {type, version}) => {
    switch (type) {
        case SET_VERSION:
            localStorage.setItem(DATALAYER, version)
            return version
        default:
            return state
    }
}


