import React from 'react';
import ReactDOM from 'react-dom';
import './style/index.css';
import {App} from './App'
import {ApolloProvider} from "@apollo/client";
import {client} from "./client";
import {BrowserRouter} from "react-router-dom";
import {rootReducer} from "./redux/reducers/rootReducer";
import { applyMiddleware, createStore } from "redux"
import thunk from "redux-thunk"
import { composeWithDevTools } from "redux-devtools-extension"
import { Provider as ReduxProvider } from "react-redux"



const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk)),
)


ReactDOM.render(
  <React.StrictMode>
  <ReduxProvider store={store}>
    <ApolloProvider client={client}>
      <BrowserRouter>
       <App />
      </BrowserRouter>
    </ApolloProvider>
  </ReduxProvider>
  </React.StrictMode>,
  document.getElementById('root')
)


