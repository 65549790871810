import {useSelector} from "react-redux"
import {useEffect, useState} from "react"

export const NONE = "No Tracking"
const UA_WORKING = "Universal Analytics (Working)"
export const PDP = "PDP"
export const CATEGORY = "Category"
export const WK = "Cart"
export const HOME = "Home"

export const layers = [
    NONE,
    UA_WORKING,
]

const pageView = (page, version) => {
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
                'event': 'page',
                'pageUrl': window.location.host,
                'pagePath': window.location.pathname,
                'pageCategory': page,
                'pageReferrer': document.referrer,
                'customerLoginStatus': false,
                'customerId': null
            })
            break
        default:
            break
    }
}

const cartView = (products, cart, version) => {


    const totalPriceGross = products?.map(p => (p.price * cart[p.id]))
        .reduce((oldValue, newValue) => oldValue + newValue, 0)
    const totalPriceNet = products?.map(p => (+cart[p.id] * (+p.vatAmount + +p.price)))
        .reduce((oldValue, newValue) => oldValue + newValue, 0)

    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
                'event': 'cartView',
                'numberOfProducts': Object.keys(cart).length,
                'totalPrice': totalPriceNet,
                'totalPriceGross': totalPriceGross,
                'products': products.map(p => (
                    {
                        'name': p.productName,
                        'id': p.id,
                        'price': p.price,
                        'category': p.categoryName, // category of the product
                        'quantity': cart[p.id]
                    }
                )),
            })
            break
        default:
            break
    }
}

const productImpression = (p, location, position, version) => {
    return
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push(
                {
                    'name': p.productName,
                    'id': p.id,
                    'price': p.price,
                    'brand': 'None', // brand of the product
                    'category': p.categoryName, // category of the product
                    'color': 'None', // color of the product
                    'variant': 'None', // variant of the product
                    'position': position, // position of the product in the list
                    'list': 'Home'
                }
            )
            break
        default:
            break
    }
}


const productClick = (p, location, version, position) => {
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
    'ecommerce': {
        'currencyCode': 'EUR',
        'click': {
            'actionField': {
                'list': 'Category' // list parameter, can be 'Home', 'PDP', 'Category', etc.
            },
            'products': [{
                'name': p.productName,
                'id': p.id, //  unique ID of the product (previously passed as 'sku')
                 'price': p.price, // price of the product
                'brand': 'None', // brand of the product
                'category': p.categoryName, // category of the product
                'color': 'None', // color of the product
                'variant': 'None', // variant of the product
                'position': position // position of the product in the list
            }]
        }
    },
  'event': 'productClick'
});
            break
        default:
            break
    }
};


const addToCart = (p, location, version) => {
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'EUR',
                     'location': location, // can be PDP', 'Category', or 'Cart' depending from which page a user adds a product to the cart
                    'add': {
                        'products': [{
                            'name': p.productName,
                            'id': p.id,
                            'price': p.price,
                            'brand': 'None',
                            'category': 'p.categoryName',
                            'color': 'None',
                            'variant': 'None',
                            'quantity': '1'
                            }
                        ]
                    }
                },
      'event': 'addToCart'
});
            break
        default:
            break
    }
};


const removeFromCart = (p, location, version) => {
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
            'ecommerce': {
                'currencyCode': 'EUR',
                'location': location, // can be PDP', 'Category', or 'Cart' depending from which page a user adds a product to the cart
                'remove': {
                    'products': [{
                        'name': p.productName,
                        'id': p.id,
                        'price': p.price,
                        'brand': 'None',
                        'category': 'p.categoryName',
                        'color': 'None',
                        'variant': 'None',
                        'quantity': '1'
                    }
                ]
            }
        },
    'event': 'removeFromCart'
});
            break
        default:
            break
    }
};



const orderConfirmation = (p, location, version) => {
    switch (version) {
        case UA_WORKING:
            window.dataLayer.push({
                'ecommerce': {
                    'currencyCode': 'EUR',
                    'paymentMethodSelected': 'None', // please pass the payment methods selected for this transaction *optional
                    'shippingMethodSelected': 'None', // please pass the delivery methods selected for this transaction, e.g. 'standard' or 'express'  *optional
                    'purchase': {
                        'actionField': {
                            'id': 'None', // Transaction ID
                            'revenue': 0.00, // total price of the order
                            'tax': 0.00, // tax of the transaction
                            'shipping': 0.00, // shipping costs of the transaction without tax.
                            'coupon': 'None'  // code of the transaction related coupon, please omit the key-value pair if no coupon has been applied
                        },
                        'products': [{
                            'name': p.productName,
                            'id': p.id,
                            'price': p.price,
                            'brand': 'None',
                            'category': 'p.categoryName',
                            'color': 'None',
                            'variant': 'None',
                            'quantity': '1'
                        }]
                    }
                },
      'event': 'purchase'
      });
            break
        default:
            break
    }
};



export const useTracking = () => {

    const version = useSelector(s => s.trackingVersion)
    const cart = useSelector(s => s.cart)
    const [hasTriggered, setHasTriggered] = useState(false)

    return {
        pageView: page => pageView(page, version),
        cartView: (products) => cartView(products, cart, version),
        productImpression: (product, location, position) => productImpression(product, location, position, version),
        productClick: (product, location, position) => productClick(product, location, position, version),
        addToCart: (product, location) => addToCart(product, location, version),
        removeFromCart: (product, location) => removeFromCart(product, location, version),
        orderConfirmation: (product, location) => orderConfirmation(product, location, version),
        hasTriggered,
        shouldTrigger: !hasTriggered,
        setTriggered: () => setHasTriggered(true)
    }
}

export const useVisible = (ref) => {
    const [isIntersecting, setIntersecting] = useState(false)


    useEffect(() => {
        if (ref?.current) {
            const observer = new IntersectionObserver(
                ([entry]) => setIntersecting(entry.isIntersecting)
            )

            observer.observe(ref.current)
            return () => {
                observer.disconnect()
            }
        }
    }, [ref])

    return isIntersecting
}
