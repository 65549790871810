import {ApolloClient, HttpLink, InMemoryCache} from "@apollo/client";


export const client = new ApolloClient(
    {
        cache: new InMemoryCache(),
        link: new HttpLink(
            {
                uri: `${window.location.protocol}//${window.location.hostname}/v1/graphql/`
            }
        )
    }
)